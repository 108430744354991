const images = require.context('../src/public/img', true)
const fonts = require.context('../src/public/fonts', true)

import jquery from 'jquery'
import moment from 'moment'
import 'jquery.cookie'
import HystModal from 'src/public/libs/hystmodal'
import 'src/public/libs/bodyScrollLock'
import customSelect from 'custom-select'
import DynamicAdapt from 'src/public/libs/dynamic-adapt'
import 'src/public/libs/spoilers'
import Swiper from 'src/public/libs/swiper-bundle'
import 'jquery.maskedinput/src/jquery.maskedinput'
import Swal from 'sweetalert2'

import 'src/public/stylesheets/style'
import 'src/public/stylesheets/hystmodal'
import 'src/public/stylesheets/helpers'
import 'src/public/stylesheets/custom'

global.DynamicAdapt = DynamicAdapt;
global.Swiper = Swiper;
global.customSelect = customSelect;
global.Swal = Swal;
global.HystModal = HystModal;
global.moment = moment;

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

moment.locale('ru');

import 'src/public/main'